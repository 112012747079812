import React, { createContext, useMemo, useReducer } from 'react';
import { IPanelContextProviderProps, IContextValues, IAction } from './types';

const initialValues: IContextValues = {
  activePanelById: '',
  isShowMobilMenu: false,
  routeHistory: [],
};

export const PanelContext = createContext<{
  state: IContextValues;
  dispatch: React.Dispatch<IAction>;
}>({
  state: initialValues,
  dispatch: () => null,
});

export const panelContextReducer = (
  state: IContextValues,
  action: IAction
): IContextValues => {
  switch (action.type) {
    case 'CHANGE':
      if (action.payload) {
        return { ...state, ...action.payload };
      } else return state;
    case 'CLEAR_ALL':
      return { ...initialValues };
    default:
      return state;
  }
};

const PanelContextProvider: React.FC<IPanelContextProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(panelContextReducer, initialValues);

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <PanelContext.Provider value={value}>{children}</PanelContext.Provider>
  );
};

export default PanelContextProvider;
