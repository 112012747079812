import { useContext } from 'react';
import { PanelContext } from '@/context/panel-context-provider/PanelContextProvider';

export const usePanelContext = () => {
  const context = useContext(PanelContext);

  if (!context) {
    throw new Error('Context data could not be loaded.');
  }

  return context;
};
