import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IHeaderProps } from './types';
import { Container } from '@/components/base/gridview';
import Anchor from '@/components/base/anchor';
import Icon from '@/components/base/icon';
import Image from '@/components/base/image';
import { functions, useApp } from '@wap-client/core';
import MobilePanel from '@/components/widgets/header/mobile-panel';
import QAPanel from '@/components/widgets/header/qa-panel';
import PanelContextProvider from '@/context/panel-context-provider';
import { usePanelContext } from '@/hooks/usePanelContext';
import { QA_PANEL_WAP_MOCK_DATA } from './qa-panel/mock-data';
import SearchPanel from './search-panel';
import useDeviceType from '@/hooks/useDeviceType';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import useAuthStore from '@/context/useAuth';
import { NodeEnv } from '@wap-client/constants';
import { getUserCheckInfo } from '@/services/goturkiye';

const animations = {
  container: {
    hidden: {
      opacity: 0,
      y: '-100%',
    },
    show: {
      opacity: 1,
      y: '0%',
      transition: {
        duration: 0.5,
        delay: 0.5,
        delayChildren: 0.9,
        staggerChildren: 0.15,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
  item: {
    hidden: {
      opacity: 0,
      x: -50,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
  actions: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.5,
        delayChildren: 0.9,
        staggerChildren: 0.2,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const Header: React.FC<IHeaderProps> = ({ logo, navigation, searchPanel }) => {
  const [isShowSearchPanel, setIsShowSearchPanel] = useState<boolean>(false);
  const [scrolled, setIsScrolled] = useState<boolean>(false);
  const deviceType = useDeviceType();

  const handleSearchPanel = () => {
    setIsShowSearchPanel(!isShowSearchPanel);
  };

  const handleBuildRoute = () => {
    const route = document.querySelector('.build-routes');
    if (route) {
      route.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const IntermediateElement: React.FC<IHeaderProps> = () => {
    const { dispatch } = usePanelContext();

    const handleMobileMenu = () => {
      dispatch({
        type: 'CHANGE',
        payload: { isShowMobilMenu: true, routeHistory: [''] },
      });
    };

    return (
      <button className="header-actions-btn menu" onClick={handleMobileMenu}>
        <Icon name="icon-hamburger-menu" />
      </button>
    );
  };

  type Callback = (...args: any[]) => void;

  const debounce = <T extends Callback>(fn: T, delay: number): T => {
    let timerId: ReturnType<typeof setTimeout> | undefined;
    return ((...args: Parameters<T>) => {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => fn(...args), delay);
    }) as T;
  };

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (
        window.scrollY > 100 &&
        (deviceType === 'desktop' || deviceType === 'laptop')
      ) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }, 30);

    if (deviceType !== 'mobile') {
      document.addEventListener('scroll', handleScroll);
    }

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [deviceType]);

  return (
    <PanelContextProvider>
      <motion.section
        className={functions.classnames(
          'header-layout',
          scrolled && 'scrolled'
        )}
        variants={animations.container}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
      >
        <Container
          size={
            scrolled
              ? 'fluid'
              : !scrolled && deviceType !== 'desktop' && deviceType !== 'laptop'
              ? 'fluid'
              : 'extended'
          }
          className="header"
        >
          {logo && (
            <Anchor
              motion={{ variants: animations.item }}
              className="header-logo"
              rootRoute
            >
              <Image {...logo} />
            </Anchor>
          )}

          <nav>
            <ul>
              {navigation &&
                navigation.map((navItem, index) => (
                  <motion.li key={index} variants={animations.item}>
                    <Anchor {...navItem}>{navItem.title}</Anchor>
                  </motion.li>
                ))}
            </ul>
          </nav>

          <motion.div
            className="header-actions"
            variants={animations.actions}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
          >
            <motion.button
              className="header-actions-btn search"
              variants={animations.item}
              onClick={handleSearchPanel}
            >
              <Icon name="icon-search" />
            </motion.button>
            <motion.button
              id="qa-panel-open-btn"
              className="header-actions-btn route"
              variants={animations.item}
              onClick={handleBuildRoute}
            >
              <Icon name="icon-build-route" />
            </motion.button>
            <HeaderUserButton />
            {deviceType !== 'desktop' && <IntermediateElement />}
          </motion.div>
        </Container>
        {deviceType !== 'desktop' && <MobilePanel />}

        <SearchPanel
          navigation={searchPanel?.navigation}
          recommendations={searchPanel?.recommendations}
          handleSearchPanel={handleSearchPanel}
          isShowSearchPanel={isShowSearchPanel}
          setIsShowSearchPanel={setIsShowSearchPanel}
        />
      </motion.section>
    </PanelContextProvider>
  );
};

export const HeaderUserButton = () => {
  const { authData, logoutUser } = useAuthStore();
  const app = useApp();

  const router = useRouter();
  const userName = authData?.user?.given_name?.slice(0, 2).toUpperCase() || '';

  const onUserAction = useCallback(() => {
    const baseUrl =
      process.env.NEXT_PUBLIC_ENV === NodeEnv.Production
        ? '/'
        : `/${app.environment.key}/`;
    const profileHref = app.settings.routes['my-profile'];
    if (authData.isAuth && profileHref?.href) {
      router.push(`${baseUrl}${profileHref.href}`);
    } else {
      router.push('/auth/sign-in');
    }
  }, [authData]);

  useEffect(() => {
    getUserCheckInfo(false, () => {
      logoutUser();
    });
  }, []);

  return (
    <motion.button
      className="header-actions-btn user"
      variants={animations.item}
      onClick={() => onUserAction()}
    >
      {authData?.isAuth ? (
        <span className="header-user-avatar">{userName}</span>
      ) : (
        <Icon name="icon-user-circle" />
      )}
    </motion.button>
  );
};
export default Header;
