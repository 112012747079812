import { queryService } from '@wap-client/services';
import { UISearchAsset } from './types';
import { REGION, SEARCH } from '../../constants';
import { UIRegion } from '../../discover/types';
import { EnvironmentContext } from '@wap-client/constants';

export const getRegions = async (
  environment: EnvironmentContext,
  language: string,
  setRegions: (data: UIRegion[]) => void
) => {
  try {
    const response = await queryService.run<UIRegion[]>(
      environment,
      { name: 'get-app-ta-regions', language, query: '' },
      REGION
    );
    if (response) {
      setRegions(response.data);
    }
  } catch (err) {
    console.error('Error fetching regions:', err);
  }
};

export const getAssets = async (
  query: string,
  environment: EnvironmentContext,
  language: string
): Promise<{
  cities: UISearchAsset[];
  assets: UISearchAsset[];
  routes: UISearchAsset[];
  combined: UISearchAsset[];
}> => {
  const params = new URLSearchParams();
  if (query) params.append('search', query);

  try {
    const citiesResponse = await queryService.run<UISearchAsset[]>(
      environment,
      {
        name: 'tourism-assets-cities-search-veri-sorgusu',
        language,
        query: params.toString(),
      },
      SEARCH
    );

    const assetsResponse = await queryService.run<UISearchAsset[]>(
      environment,
      {
        name: 'tourism-assets-things-to-do-search-veri-sorgusu',
        language,
        query: params.toString(),
      },
      SEARCH
    );

    const routesResponse = await queryService.run<UISearchAsset[]>(
      environment,
      {
        name: 'trip-route-search-veri-sorgusu',
        language,
        query: params.toString(),
      },
      SEARCH
    );

    const cities = citiesResponse?.data || [];
    const assets = assetsResponse?.data || [];
    const routes = routesResponse?.data || [];

    const combined = [...cities, ...assets, ...routes];

    return { cities, assets, routes, combined };
  } catch (err) {
    console.error('Error fetching assets:', err);
    return { cities: [], assets: [], routes: [], combined: [] };
  }
};
