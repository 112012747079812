import React, { useEffect, useLayoutEffect, useState } from 'react';
import { IMobilePanelProps, IGetMenuPanelPage, IPanelPage } from './types';
import Icon from '@/components/base/icon';
import { functions, useApp } from '@wap-client/core';
import render from '@/core/render';
import { queryService, mappers } from '@wap-client/services';
import { usePanelContext } from '@/hooks/usePanelContext';
import Spinner from '@/components/base/spinner';
import router from 'next/router';

const MobilePanel: React.FC<IMobilePanelProps> = ({}) => {
  const [panelPages, setPanelPages] = useState<Array<IPanelPage>>([]);
  const [isActivePanelPage, setIsActivePanelPage] = useState<IPanelPage>({});
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [cannotFindPage, setCannotFindPage] = useState<boolean>(false);

  const app = useApp();
  const { state: panelContextState, dispatch: setPanelContextState } =
    usePanelContext();

  const convertRenderComponent = (
    resData: Array<IGetMenuPanelPage>
  ): Array<IPanelPage> => {
    const convertedDatas = resData.reduce(
      (newDataArray: Array<IPanelPage>, data: IGetMenuPanelPage) => {
        const newData: IPanelPage = {};
        const { schema } = data.page;

        newData.views = mappers.getReference(
          schema.value,
          mappers.getRefsObject(schema.refs, app.page)
        );

        newData.layout = mappers.getLayoutSchema(
          schema.layout,
          mappers.getRefsObject(schema.refs, {
            ...app.page,
            views: newData.views,
          })
        );

        const { page, ...rest } = data;

        newDataArray.push({ ...newData, ...rest });

        return newDataArray;
      },
      []
    );
    return convertedDatas || [];
  };

  useLayoutEffect(() => {
    if (panelContextState.isShowMobilMenu) {
      document.body.classList.add('disabled-scroll');
    }

    return () => {
      document.body.classList.remove('disabled-scroll');
    };
  }, [panelContextState.isShowMobilMenu]);

  useEffect(() => {
    queryService
      .run<Array<IGetMenuPanelPage>>(app.environment, {
        language: app.language,
        name: 'get-app-menu-panel',
      })
      .then((res) => {
        setPanelPages(convertRenderComponent(res.data));
        setError(false);
        setLoading(true);
      })
      .catch((err) => {
        console.error('Error fetching menu panel:', err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [app.language, app.environment]);

  useEffect(() => {
    const { activePanelById } = panelContextState;

    if (panelPages.length > 0) {
      setCannotFindPage(false);
      const activePage = panelPages.find((page) => page.id === activePanelById);

      if (!activePage) {
        setIsActivePanelPage(panelPages[0]);
      } else {
        setIsActivePanelPage(activePage);
      }
    } else {
      setCannotFindPage(true);
    }
  }, [panelContextState.activePanelById, panelPages]);

  useEffect(() => {
    const hanleClearContext = () => {
      setPanelContextState({ type: 'CLEAR_ALL' });
    };

    router.events.on('routeChangeStart', hanleClearContext);

    return () => {
      router.events.off('routeChangeStart', hanleClearContext);
    };
  }, [router.events]);

  const renderPanelBody = () => {
    if (error) {
      return (
        <div className="error-layout">
          <Icon name="icon-error" size="large" />
          <span>{app.settings.translations['error-menu-panel']}</span>
        </div>
      );
    }

    if (loading) {
      return (
        <div className="error-layout">
          <Spinner />
        </div>
      );
    }

    if (cannotFindPage) {
      return (
        <div className="error-layout">
          <Icon name="icon-warning" size="large" />
          <span>404 Not Found</span>
          <span>
            {app.settings.translations['error-cannot-find-panel-page']}
          </span>
        </div>
      );
    }

    if (panelPages.length === 0) {
      return (
        <div className="error-layout">
          <Icon name="icon-warning" size="large" />
          <span>{app.settings.translations['error-empty-panel']}</span>
        </div>
      );
    }

    if (isActivePanelPage && !cannotFindPage) {
      return render(isActivePanelPage.layout, isActivePanelPage.views);
    }
  };

  return (
    <div
      className={functions.classnames(
        'mobile-panel',
        panelContextState.isShowMobilMenu && 'show-menu'
      )}
    >
      {renderPanelBody()}
    </div>
  );
};

export default MobilePanel;
