import Icon from '@/components/base/icon';
import { useApp } from '@wap-client/core';

const BuildRouteBox = () => {
  const app = useApp();

  const handleBuildRoute = () => {
    const targetButton = document.getElementById('search-panel-close');
    const route = document.querySelector('.build-routes');

    if (targetButton) {
      targetButton.click();
    }

    if (route) {
      route.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="build-route-box">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 744.487 741.821"
      >
        <path
          d="M47.8,91.435a85.229,85.229,0,0,1-44.153-11.8A88.752,88.752,0,0,1-28.147,47.839a85.286,85.286,0,0,1-11.8-44.181,84.572,84.572,0,0,1,11.8-44.026A89.05,89.05,0,0,1,3.645-72.033,85.241,85.241,0,0,1,47.8-83.835a85.286,85.286,0,0,1,44.181,11.8,89.179,89.179,0,0,1,31.8,31.665A84.489,84.489,0,0,1,135.6,3.658a85.2,85.2,0,0,1-11.817,44.181,88.88,88.88,0,0,1-31.8,31.792A85.274,85.274,0,0,1,47.8,91.435Zm0-45.167q17.945,0,30.164-12.333T90.181,3.658q0-17.941-12.206-30.134T47.8-38.668A41.043,41.043,0,0,0,17.8-26.462Q5.471-14.257,5.471,3.658T17.817,33.923A40.818,40.818,0,0,0,47.8,46.268ZM24.334,99.641v-20.1H71.292v20.1q0,47.591,64.89,65.51l133.35,36.795q48.312,13.117,73.183,41.125t24.871,69.2V332.15H320.37V312.273q0-47.395-64.886-65.51l-133.354-36.8q-48.312-13.091-73.054-41.236T24.334,99.641ZM344.117,320.225a83.886,83.886,0,0,1,44.026,11.928,90.413,90.413,0,0,1,31.665,31.792,88.542,88.542,0,0,1,0,88.332,89.373,89.373,0,0,1-31.665,31.665,88.546,88.546,0,0,1-88.334,0,90.419,90.419,0,0,1-31.79-31.665,87.727,87.727,0,0,1,0-88.332,91.478,91.478,0,0,1,31.79-31.792A84.4,84.4,0,0,1,344.117,320.225Z"
          transform="matrix(0.695, -0.719, 0.719, 0.695, 88.057, 368.711)"
          fill={app.theme.colors.primary[75]}
        />
      </svg>
      <Icon
        name="icon-build-route"
        className="build-route-box-icon"
        size="xlarge"
      />
      <div className="build-route-box-title">
        {app.settings.translations['build-route-box-title']}
      </div>
      <div className="build-route-box-desc">
        {app.settings.translations['build-route-box-desc']}
      </div>
      <button onClick={handleBuildRoute} className="build-route-box-button">
        {app.settings.translations['build-route-box-button']}
      </button>
    </div>
  );
};

export default BuildRouteBox;
